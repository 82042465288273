import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

// 挂载vuex
Vue.use(Vuex)

// 创建vuex对象
const store = new Vuex.Store({
  state:{
    account:'',
    department:'',
    bin:'',
  },
  mutations:{
    updateAccount(state, account){
      state.account = account
    },
    updateDepartment(state, department){
      state.department = department
    },
    updateBin(state, bin){
      state.bin = bin
    },
    // clearAccount(state){
    //   state.account = ''
    // }
  },
  getters: {
    getAccount: (state) => state.account,
    getDep: (state) => state.department,
    getBin: (state) => state.bin,
    // 这里是获取 userTYpe 的方法，调用方法是 this.$store.getters.getUserType
  },
  plugins: [
    createPersistedState({
      key: "vuex",
      reducer(vuex) {
        return vuex;
      },
    }),
  ],
})

export default store