import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'',
    redirect:'/home'
  },
  {
    path:'',
    name:'Layout',
    component:() => import('views/Layout.vue'),
    redirect:'home',
    children:[
      {
        path: 'home',
        name: 'home',
        component: () => import('views/home/Home.vue'),
        meta:{
          title:'首页',
          path:'/home',
          icon: "el-icon-menu"
        },
      },
      // {
      //   path: 'goods_increment',
      //   name: 'Increment',
      //   component: () => import('@/views/goods/GoodsIncrement.vue'),
      //   meta:{
      //     title:'商品添加',
      //     path:'/goods_increment'
      //   },
      // },
      {
        path: 'searchWeb',
        name: 'searchWeb',
        component: () => import('@/views/webManage/searchWeb.vue'),
        meta:{
          title:'网站管理',
          path:'/searchWeb',
          icon: "el-icon-platform-eleme",
        },
      },
      {
        path: 'AddGoods',
        name: 'Increment',
        component: () => import('@/views/goods/AddGoods.vue'),
        meta:{
          title:'商品添加',
          path:'/AddGoods',
          icon: "el-icon-circle-plus",
        },
      },
      {
        path:'goods_list',
        name:'ProductList',
        component: () => import('@/views/goods/ProductList.vue'),
        meta:{
          title:'商品列表',
          path:'/goods_list',
          icon: "el-icon-goods",
        }
      },
      // {
      //   path:'order',
      //   name:'Order',
      //   component: () => import('views/order/Order.vue'),
      //   meta:{
      //     title:'订单',
      //     path:'/order'
      //   }
      // },
      {
        path:'payerMaxOrder',
        name:'payerMaxOrder',
        component: () => import('@/views/order/payerMaxOrder.vue'),
        meta:{
          title:'PayerMax订单',
          path:'/payerMaxOrder',
          icon: "el-icon-s-order",
        }
      },
      {
        path:'szOrder',
        name:'szOrder',
        component: () => import('views/order/szOrder.vue'),
        meta:{
          title:'善枝订单',
          path:'/szOrder',
          icon: "el-icon-s-cooperation",
        }
      },
      {
        path:'paypalOrder',
        name:'paypalOrder',
        component: () => import('views/order/paypalOrder.vue'),
        meta:{
          title:'paypal 订单',
          path:'/paypalOrder',
          icon: "el-icon-s-data",
        }
      },
      {
        path:'pp',
        name:'pp',
        component: () => import('@/views/orderDialog/codeSearch/pp.vue'),
        meta:{
          title:'Paypal状态码查询',
          path:'/pp',
          // icon: "el-icon-s-data",
        }
      },
      {
        path:'ps',
        name:'ps',
        component: () => import('@/views/orderDialog/codeSearch/ps.vue'),
        meta:{
          title:'Psmile状态码查询',
          path:'/ps',
          // icon: "el-icon-s-data",
        }
      },
      {
        path:'psOrder',
        name:'psOrder',
        component: () => import('views/order/psOrder.vue'),
        meta:{
          title:'Psmile订单',
          path:'/psOrder',
          icon: "el-icon-s-claim",
        }
      },
      {
        path:'lottery_increment',
        name:'LotteryIncrement',
        component: () => import('views/lottery/LotteryIncrement.vue'),
        meta:{
          title:'添加活动和概率设置',
          path:'/lottery_increment',
          icon: "el-icon-plus",
        }
      },
      {
        path:'lottery_list',
        name:'LotteryList',
        component: () => import('views/lottery/LotteryList.vue'),
        meta:{
          title:'活动列表',
          path:'/lottery_list',
          icon: "el-icon-medal-1",
        }
      },
      {
        path:'generate_code',
        name:'GenerateCode',
        component: () => import('@/views/lottery/GenerateCode.vue'),
        meta:{
          title:'生成抽奖码',
          path:'/generate_code',
          icon: "el-icon-s-opportunity",
        }
      },
      {
        path:'code_list',
        name:'CodeList',
        component: () => import('@/views/lottery/CodeList.vue'),
        meta:{
          title:'获取抽奖码',
          path:'/code_list',
          icon: "el-icon-magic-stick",
        }
      },
      {
        path:'lucky_record',
        name:'LuckyRecord',
        component: () => import('@/views/lottery/LuckyRecord.vue'),
        meta:{
          title:'中奖记录',
          path:'/lucky_record',
          icon: "el-icon-magic-stick",
        }
      },
      {
        path:'UploadDelivery',
        name:'UploadDelivery',
        component: () => import('@/views/modules/UploadDelivery.vue'),
        meta:{
          title:'导入快递号',
          path:'/UploadDelivery',
          icon: "el-icon-upload",
        }
      },
    ]
  },
  {
    path:'/login',
    name:'Login',
    component: () => import('views/login/Login.vue')
  },
  {
    path:'/lucky_draw',
    name:'LuckyDraw',
    component: () => import('views/lucky/LuckyDraw.vue')
  },
  // {
  //   path:'/lucky_draw',
  //   name:'LuckyDraw',
  //   component: () => import('views/orderDialog/paypal/payDetail.vue')
  // },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router