import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"

import axios from 'axios';
Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios

import './assets/css/common.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/variables.scss'
Vue.use(ElementUI);

// import VueLuckyCanvas from '@lucky-canvas/vue'
// Vue.use(VueLuckyCanvas)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

// import $ from 'jquery';
// window.jQuery = $; // 让 jQuery 在全局可用
// Vue.use($)
// import 'summernote'
// import 'summernote/dist/summernote-bs4.css';
// import 'summernote/dist/summernote-bs4.js';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

